<template>
    <div class="empty-layout">
        <img class="empty-layout__img" src="@/assets/imgs/404.png" />
        <div class="empty-layout__title">呼叫总部，我们进入了一个不存在的页面</div>
        <div class="empty-layout__btn" @click="goHome">返回首页</div>
    </div>
</template>

<script>
export default {
    methods: {
        goHome() {
            location.replace('/')
        }
    }
}
</script>

<style scoped>
.empty-layout {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    position: relative;
}

.empty-layout__img {
    margin-top: 100px;
    width: 200px;
}

.empty-layout__title {
    margin-top: 30px;
    margin-bottom: 20px;
    color: #999CA0;
}

.empty-layout__btn {
    color: #f84c1e;
    padding: 6px 16px;
    border: 1px solid rgba(193,194,197,1);
    border-radius: 8px;
}
</style>